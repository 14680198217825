import { Link } from 'gatsby';
import React from 'react';
import { ToastContainer } from 'react-toastify';
import Logo from '../assets/images/logo.svg';
import { Helmet } from 'react-helmet';
import {
  FacebookIcon,
  FacebookShareButton,
  OKIcon,
  OKShareButton,
  TelegramIcon,
  TelegramShareButton,
  TwitterIcon,
  TwitterShareButton,
  VKIcon,
  VKShareButton,
} from 'react-share';

export default function Layout({ children, header, descr, showFooter }) {
  return (
    <div className="min-h-screen bg-my-grey">
      <Helmet
        htmlAttributes={{
          lang: 'ru',
        }}
      >
        <meta charSet="utf-8" />
        <link type="image/x-icon" rel="shortcut icon" href="./favicon.ico" />
      </Helmet>
      <div className="min-h-screen relative px-4 py-20px mx-auto max-w-full lg:max-w-screen-xl md:px-8  lg:py-50px">
        <div className="flex-col mb-50px text-center">
          <Link
            to="/"
            className=" text-text text-my-violet mb-40px md:mb-50px flex justify-start	md:justify-center"
          >
            <Logo className="w-64" />
          </Link>
          <h1 className="text-h1 font-bold mb-20px text-my-black">{header}</h1>
          <span
            className="text-descr text-my-dividers"
            dangerouslySetInnerHTML={{ __html: descr }}
          />
        </div>
        {children}

        <div className="flex flex-col mt-60px">
          {/* <div className="flex flex-col md:flex-row"> */}
          <div className="flex flex-col md:flex-col">
            {/* <div className="md:w-1/3"> */}
            <div className="w-full space-x-20px flex">
              <div className="text-bigFriends font-bold mb-20px">
                Расскажите друзьям!
              </div>
              <div className="space-x-20px">
                <VKShareButton
                  url="https://prettyfont.net/"
                  // image={`${String(window.location)}/${exampleImage}`}
                  className="Demo__some-network__share-button"
                >
                  <VKIcon size={32} round />
                </VKShareButton>
                <FacebookShareButton
                  url="https://prettyfont.net/"
                  quote="Красивые шрифты онлайн. Напиши и скопируй 🤩"
                >
                  <FacebookIcon size={32} round />
                </FacebookShareButton>
                <OKShareButton
                  url="https://prettyfont.net/"
                  // image={`${String(window.location)}/${exampleImage}`}
                  className="Demo__some-network__share-button"
                >
                  <OKIcon size={32} round />
                </OKShareButton>
                <TwitterShareButton
                  url="https://prettyfont.net/"
                  title="Красивые шрифты онлайн. Напиши и скопируй 🤩"
                  className="Demo__some-network__share-button"
                >
                  <TwitterIcon size={32} round />
                </TwitterShareButton>
                <TelegramShareButton
                  url="https://prettyfont.net/"
                  title="Красивые шрифты онлайн. Напиши и скопируй 🤩"
                  className="Demo__some-network__share-button"
                >
                  <TelegramIcon size={32} round />
                </TelegramShareButton>
              </div>
            </div>
            <div className="w-full space-x-20px flex flex-wrap">
              <div className="text-descr mb-20px">Подписывайтесь на нас:</div>
              <a
                className="text-descr mb-20px hover:underline text-my-blue hover:text-my-violet"
                href="https://vk.com/prettyfont"
              >
                vk.com/PrettyFont
              </a>
            </div>
            {/* <div className="text-my-dividers md:w-2/3 prose"> */}
            {showFooter && (
              <div className="text-my-dividers w-full prose text-base">
                <p>
                  Сложно выделиться на сайте, когда все логины и тексты выглядят
                  абсолютно одинаково. Если вы хотите, чтобы ваши посты были
                  уникальными, креативными и красивыми, то используйте наш
                  сервис. Здесь вы можете взять шикарные шрифты для Инстаграм
                  абсолютно бесплатно. Выделяйся из серой массы.
                </p>
                <h2>Зачем нужно изменение шрифта на сайте</h2>
                <p>
                  Казалось бы, все итак выглядит отлично. Однако, в бесконечном
                  потоке статей и публикаций, пользователь просто вас не
                  заметит. Это абсолютно не подходит тем, кто хочет привлечь
                  внимание: блогерам, мастерам, предоставляющим услуги, или
                  бизнесменам. Распространенность популярности товара или
                  контента напрямую зависит от того, как вы сможете привлечь
                  новых пользователей. С обычными шрифтами у вас ничего не
                  выйдет.
                </p>
                <p>В постах пользователь двигается следующим образом:</p>
                <ul>
                  <li>
                    Просмотр картинки. Если она его заинтересует, то он перейдет
                    к тексту.
                  </li>
                  <li>
                    Чтение поста, что подразумевается под картинкой или что
                    хочет сказать автор.
                  </li>
                  <li>Просмотр профиля автора или его канала.</li>
                </ul>
                <p>
                  Если картинку можно легко сделать в фотошопе, то с постами все
                  значительно сложнее. В том же Инстаграм, они представляют
                  собой бесформенное нечто, которое разве что можно выделить
                  смайлами. Мы же предоставляем совершенно иной подход.
                </p>
                <h2>Как написать фразу красивым шрифтом</h2>
                <p>
                  Нет ничего проще, чем это. На сайте предлагаются крутые
                  шрифты, которые помогут вам выделить свои публикации и сделать
                  уникальное оформление ников в социальных сетях. Для этого
                  сделайте ряд простых шагов:
                </p>
                <ol>
                  <li>
                    В поле, где написано &laquo;пример текста&raquo; введите то,
                    что хотите написать. Ограничений по количеству символов нет.
                  </li>
                  <li>
                    Выберите украшение текста, которое хотите использовать. Оно
                    будет отображаться справа или слева, в зависимости от Ваших
                    предпочтений.
                  </li>
                  <li>
                    Просто листайте вниз и просматривайте различные шрифты.
                  </li>
                  <li>
                    То оформление, которое Вам понравится, вы можете скопировать
                    и использовать в своей публикации.
                  </li>
                </ol>
                <p>
                  У нас также есть отдельная фишка: изменить текст. Так вы
                  сможете оформить его в более крутом формате. Как это работает?
                  Возле понравившегося шрифта нажмите кнопку
                  &laquo;изменить&raquo;. Вас перенесет на другую страницу.
                  Здесь вы можете сделать красивый текст вместе с:
                </p>
                <ul>
                  <li>Эмоциями и смайликами.</li>
                  <li>Изображением людей и частей тел.</li>
                  <li>С животными, птицами и насекомыми.</li>
                  <li>Растениями, цветами и природой.</li>
                  <li>Предметами и флагами.</li>
                  <li>Символами и знаками.</li>
                </ul>
                <p>
                  Всего мы предоставляем 10 различных категорий, которые сделают
                  шрифты для ников еще более крутыми. В каждой из них от 10 до
                  нескольких сотен различных вариантов изображений.
                </p>
                <p>
                  Вы можете использовать красивые буквы для ников , делая его
                  уникальным. Допустим, выбрать вычурную заглавную или сделать
                  текст с картинкой, смайлом, эмоцией. Плюс нашего сайта в том,
                  что вы можете делать это без каких-либо ограничений! Мы
                  предоставляем возможность пользования сервисом абсолютно
                  бесплатно. При этом &ndash; шрифтов у нас очень много!
                </p>
              </div>
            )}
          </div>
          <div className="flex mt-70px text-my-dividers flex-col md:flex-row items-center space-y-20px md:space-y-0">
            <span className="md:w-1/3">PrettyFont.net, 2021</span>
            <span className="md:w-2/3 flex flex-col md:flex-row space-y-20px md:space-y-0 md:space-x-20px">
              <Link to="/privacy-policy/">Политика конфиденциальности</Link>
              <span>Пользовательское соглашение</span>
            </span>
          </div>
        </div>
      </div>
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
}
